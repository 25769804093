import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { SupplierBasic } from '@mpsm/shared/domain';

@Component({
  selector: 'mpctm-condition-supplier',
  standalone: true,
  templateUrl: './condition-supplier.component.html',
  styleUrls: ['./condition-supplier.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, MatIconModule],
})
export class ConditionSupplierComponent {
  @Input({ required: true }) conditionSupplier!: SupplierBasic;
}
