import { NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Condition } from '@mp/contract-management/conditions/domain';

import { ConditionListItemComponent } from '../condition-list-item/condition-list-item.component';

@Component({
  selector: 'mpctm-conditions-list',
  standalone: true,
  templateUrl: './conditions-list.component.html',
  styleUrls: ['./conditions-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgFor, ConditionListItemComponent],
})
export class ConditionsListComponent {
  @Input() conditions: Condition[] = [];

  trackByFn(_index: number, { conditionId }: Condition): string {
    return conditionId;
  }
}
