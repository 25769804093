<div class="condition-info">
  <div class="condition-info__details">
    <div class="condition-info__condition-condition-number-row truncate">
      <span class="truncate" title="Konditions-Nr.">Konditions-Nr.</span>
      <span class="condition-info__condition-condition-number truncate" [title]="condition.conditionNumber">{{
        condition.conditionNumber
      }}</span>
    </div>

    <div *ngIf="condition.contractNumber" class="condition-info__condition-contract-number-row truncate">
      <span class="truncate" title="Vertrags-Nr.">Vertrags-Nr.</span>
      <span class="condition-info__condition-contract-number truncate" [title]="condition.contractNumber"
        >{{ condition.contractNumber }}
      </span>
    </div>
  </div>

  <div
    class="condition-info__condition truncate"
    [ngClass]="{ 'condition-info__condition--archived': condition.status.type === ContractStatusType.Archived }"
  >
    <span class="condition-info__condition-name" [title]="condition.conditionName">
      {{ condition.conditionName }}
    </span>
  </div>

  <div *ngIf="condition.leadingDepartment" class="condition-info__condition-type truncate">
    <span class="truncate" title="Fachbereich">Fachbereich</span>
    <span class="condition-info__condition-type-text truncate" [title]="condition.leadingDepartment">
      {{ condition.leadingDepartment }}
    </span>
  </div>

  <div class="condition-info__data-source truncate">
    <span class="truncate" title="Datenquelle">Datenquelle</span>
    <span class="condition-info__data-source-text truncate" [title]="condition.dataSource">
      {{ condition.dataSource }}
    </span>
  </div>

  <mpctm-contract-status class="condition-info__status" [statusType]="condition.status.type" />
</div>
