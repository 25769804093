import { DatePipe, NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { LetDirective } from '@ngrx/component';

import { ContractStatusType } from '@mp/contract-management/common/domain';
import { ContractStatusComponent } from '@mp/contract-management/common/ui';
import { Condition } from '@mp/contract-management/conditions/domain';

@Component({
  selector: 'mpctm-condition-info',
  standalone: true,
  templateUrl: './condition-info.component.html',
  styleUrls: ['./condition-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, NgClass, DatePipe, LetDirective, MatIconModule, ContractStatusComponent],
})
export class ConditionInfoComponent {
  @Input({ required: true }) condition!: Condition;

  readonly ContractStatusType = ContractStatusType;
}
