<div class="condition-supplier-title truncate" title="Lieferant">Lieferant</div>

<div class="condition-supplier-name" [title]="conditionSupplier.name">
  <mat-icon class="condition-supplier-name__icon material-icons-outlined">local_shipping</mat-icon>
  <span class="truncate">{{ conditionSupplier.name }}</span>
</div>

<div
  *ngIf="conditionSupplier.externalNumber"
  class="condition-supplier-number truncate"
  [title]="conditionSupplier.externalNumber"
>
  {{ conditionSupplier.externalNumber }}
</div>
