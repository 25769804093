<mpctm-contract-list-item-card [statusType]="condition.status.type">
  <div class="condition">
    <mpctm-condition-info class="condition__info" [condition]="condition" />

    <div class="condition-data">
      <mpctm-condition-supplier class="condition-data__supplier" [conditionSupplier]="condition.supplier" />

      <mpctm-contract-validity
        class="condition-data__validity"
        [validFrom]="condition.validFrom"
        [validUntil]="condition.validUntil"
      />

      <mpctm-contract-user-info
        class="condition-data__last-change"
        [userModification]="condition.modified ?? condition.created"
        [infoTitle]="condition.modified ? 'Letzte Änderung' : 'Anlage'"
      />
    </div>

    <button
      mat-flat-button
      class="condition__details-button"
      [routerLink]="['/', 'contract-management', 'conditions', condition.conditionId]"
    >
      Details
    </button>
  </div>
</mpctm-contract-list-item-card>
